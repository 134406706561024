<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row>
      <b-col>
        <b-card>
          <b-table
            :striped="false"
            :bordered="true"
            :hover="true"
            :busy="show"
            :items="listData"
            :fields="translatedTableColumns"
            :per-page="perPage"
            :current-page="currentPage"
            primary-key="id"
            responsive
            show-empty
            empty-text="No matching records found"
            class="position-relative"
            :sticky-header="stickyHeader"
          ></b-table>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <b-card>
          <b-table
            :striped="false"
            :bordered="true"
            :hover="true"
            :busy="show"
            :items="checkData"
            :fields="translatedCheckTableColumns"
            primary-key="id"
            responsive
            show-empty
            empty-text="No matching records found"
            :sticky-header="stickyHeader"
            class="checkTable"
          >
            <template #cell(FIS_TURU)="data">
              <div style="text-align:left">
                <p>{{$t(data.item.FIS_TURU) }}</p>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>-->
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
  BFormInput,
  BOverlay,
  BModal,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BTooltip
} from "bootstrap-vue";
import { t } from "../@core/libs/i18n/utils";
import moment from "moment";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { th } from "vuejs-datepicker/src/locale";
function formatSayi(sayi, ozellestirilmisSecenekler) {
  return new Intl.NumberFormat(undefined, ozellestirilmisSecenekler).format(
    sayi
  );
}
export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BLink,
    BBadge,
    BDropdown,
    BImg,
    BFormSpinbutton,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BModal,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BCol,
    BTable,
    BFormFile,
    BMedia,
    BAvatar,
    BProgress,
    BProgressBar,
    moment,
    vSelect,
    BTooltip
  },
  computed: {
    // ceviri kısmının calisma sekli
    translatedTableColumns() {
      return this.tableColumns.map(column => {
        return { ...column, label: this.$t(column.label) };
      });
    },
    translatedCheckTableColumns() {
      return this.checkTable.map(column => {
        return { ...column, label: this.$t(column.label) };
      });
    }
  },

  data() {
    return {
      ozellestirilmisSecenekler: {
        minimumFractionDigits: 2, // küsürat kısmının minimum hane sayısı
        maximumFractionDigits: 2, // küsürat kısmının maksimum hane sayısı
        minimumIntegerDigits: 2, // tam sayı kısmının minimum hane sayısı
        useGrouping: true // binlik grup ayırıcıları kullanılsın mı?
      },
      perPage: 8,
      checkperPage: 12,
      perPageOptions: [8, 16, 50, 100],
      currentPage: 1,
      show: false,
      userData: "",
      listData: [],
      checkData: [],
      stickyHeader: true,
      tableColumns: [
        {
          key: "ULKE",
          class: "ULKE",
          label: "Ülke",
          sortable: false
        },
        {
          key: "AD",
          class: "AD",
          label: "Ad",
          sortable: false
        },
        {
          key: "PRICEUNIT",
          class: "PRICEUNIT",
          label: "Para Birimi",
          sortable: false
        },
        {
          key: "BAKIYE",
          class: "BAKIYE",
          label: "Bakiye",
          sortable: false
        },
        {
          key: "D1",
          class: "D1",
          label: "Geçmiş",
          sortable: false
        },

        {
          key: "D2",
          class: "D2",
          label: "0-15",
          sortable: false
        },
        {
          key: "D3",
          class: "D3",
          label: "15-30",
          sortable: false
        },
        {
          key: "DKALANTOPLAM",
          class: "DKALANTOPLAM",
          label: "30->",
          sortable: false
        },
        {
          key: "SON_CALISMA_ZAMANI",
          class: "SON_CALISMA_ZAMANI",
          label: "Tarih",
          format: "dd/MM/yyyy",
          sortable: false
        }
      ],
      checkTable: [
        {
          key: "FIS_NO",
          class: "FIS_NO",
          label: "Fiş No",
          sortable: false
        },
        {
          key: "TARIH",
          class: "TARIH",
          label: "Tarih",
          sortable: false
        },
        {
          key: "FIS_TURU",
          class: "FIS_TURU",
          label: "Fiş Türü",
          sortable: false
        },
        {
          key: "VALOR",
          class: "VALOR",
          label: "Valor",
          sortable: false
        },
        {
          key: "SATIR_ACIKLAMASI",
          class: "SATIR_ACIKLAMASI",
          label: "Açıklama",
          sortable: false
        },
        {
          key: "BORC",
          class: "BORC",
          label: "Borç",
          sortable: false
        },
        {
          key: "ALACAK",
          class: "ALACAK",
          label: "Alacak",
          sortable: false
        },
        {
          key: "ISLEM_DOVIZI",
          class: "ISLEM_DOVIZI",
          label: "Para Birimi",
          sortable: false
        }
      ]
    };
  },
  created() {
    var uData = JSON.parse(localStorage.getItem("B2BUserData"));
    this.userData = uData;
    this.listele();

    this.$route.query.orders = [];
  },
  methods: {
    async listele() {
      this.show = true;
      this.kriterler1 = {
        metin1: this.userData.musteriKod,
        queryKey: "B2bAlacakYaslandirma"
      };
      await this.$http
        .post("v1/Dynamic/GetQueryReplaceData", this.kriterler1)
        .then(response => {
          this.show = false;
          if (response.status == 200) {
            var satirlar = JSON.parse(response.data.data.dataJson);
            var dfull = 0;
            for (var i = 0; i < satirlar.length; i++) {
              satirlar[i].BAKIYE = formatSayi(
                satirlar[i].BAKIYE,
                this.ozellestirilmisSecenekler
              );

              satirlar[i].D1 = formatSayi(
                satirlar[i].D1,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].D2 = formatSayi(
                satirlar[i].D2,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].D3 = formatSayi(
                satirlar[i].D3,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].EXIMLIMIT = formatSayi(
                satirlar[i].EXIMLIMIT,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].BORC_TUTAR = formatSayi(
                satirlar[i].BORC_TUTAR,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].BORC_BAKIYE = formatSayi(
                satirlar[i].BORC_BAKIYE,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].ALACAK_BAKIYE = formatSayi(
                satirlar[i].ALACAK_BAKIYE,
                this.ozellestirilmisSecenekler
              );
              satirlar[i].ALACAK_TUTAR = formatSayi(
                satirlar[i].ALACAK_TUTAR,
                this.ozellestirilmisSecenekler
              );
              dfull =
                dfull +
                satirlar[i].D4 +
                satirlar[i].D5 +
                satirlar[i].D6 +
                satirlar[i].D7 +
                satirlar[i].D8 +
                satirlar[i].D9 +
                satirlar[i].D10 +
                satirlar[i].D11 +
                satirlar[i].D12;

              satirlar[i].DKALANTOPLAM = formatSayi(
                dfull,
                this.ozellestirilmisSecenekler
              );
            }
            this.listData = satirlar;
            // console.log(satirlar);
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error, "Hata");
        });
    },
    // kAPATTIK ŞİMDİLİK
    async checkList() {
      this.show = true;
      this.kriterler1 = {
        metin1: this.userData.musteriKod,
        queryKey: "B2bCariHareketleri"
      };
      await this.$http
        .post("v1/Dynamic/GetQueryReplaceData", this.kriterler1)
        .then(response => {
          this.show = false;
          if (response.status == 200) {
            var satirlar = JSON.parse(response.data.data.dataJson);
            for (var i = 0; i < satirlar.length; i++) {
              satirlar[i].ALACAK = formatSayi(
                satirlar[i].ALACAK,
                this.ozellestirilmisSecenekler
              );

              satirlar[i].BORC = formatSayi(
                satirlar[i].BORC,
                this.ozellestirilmisSecenekler
              );
            }
            this.checkData = satirlar;
            // console.log(satirlar);
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error, "Hata");
        });
    }
  }
};
</script>
 <style>
.checkTable {
  max-height: 450px !important;
  border-radius: 5px !important;
}
.table .BORC {
  text-align: right !important;
  width: 70px !important;
}
.table .ALACAK {
  text-align: right !important;
  width: 70px !important;
}
.table .D1 {
  text-align: right !important;
  color: #c15146;
  width: 70px !important;
  background-color: aliceblue;
}
.table .D2 {
  text-align: right !important;
  width: 70px !important;
  color: #febe2a;
  background-color: aliceblue;
}
.table .D3 {
  text-align: right !important;
  width: 70px !important;
  color: #03bdae;
  background-color: aliceblue;
}
.table .DKALANTOPLAM {
  text-align: right !important;
  width: 70px !important;
  color: #0277bd;
  background-color: aliceblue;
}
.table .BAKIYE {
  text-align: right !important;
  width: 70px !important;
}
.table .PRICEUNIT {
  text-align: right !important;
  width: 160px !important;
}
</style>